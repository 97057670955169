<template>
    <div class="modal-terms">
        <div class="terms-header">
            <span class="gradient-green">{{ $t('modal.135') }}</span>
            <p>{{ $t('modal.136') }}</p>
        </div>
        <div class="terms-list">

            <div class="list-element">
                <div class="element-title">{{ $t('modal.137') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.138') }}</p>
                    <p>{{ $t('modal.139') }}</p>
                    <p>{{ $t('modal.140') }}</p>
                
                    <p>{{ $t('modal.141') }}</p>
                    <p>{{ $t('modal.142') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.143') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.144') }}</p>
                    <p>{{ $t('modal.145') }}</p>
                    <p>{{ $t('modal.146') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.147') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.148') }}</p>
                    <p>{{ $t('modal.149') }}</p>
                    <p>{{ $t('modal.150') }}</p>
                    <p>{{ $t('modal.151') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.152') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.153') }}</p>
                    <p>{{ $t('modal.154') }}</p>
                    <p>{{ $t('modal.155') }}</p>
                    <p>{{ $t('modal.156') }}</p>
                    <p>{{ $t('modal.157') }}</p>
                    <p>{{ $t('modal.158') }}</p>
                    <p>{{ $t('modal.159') }}</p>
                    <p>{{ $t('modal.160') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.161') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.162') }}</p>
                    <p>{{ $t('modal.163') }}</p>
                    <p>{{ $t('modal.165') }}</p>
                    
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.167') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.168') }}</p>
                    <p>{{ $t('modal.169') }}</p>
                    <p>{{ $t('modal.170') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.171') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.172') }}</p>
                    <p>{{ $t('modal.173') }}</p>
                    <p>{{ $t('modal.174') }}</p>
                    <p>{{ $t('modal.175') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.176') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.177') }}</p>
                    <p>{{ $t('modal.178') }}</p>
                    <p>{{ $t('modal.179') }}
                        <ul>
                            <li>{{ $t('modal.180') }}</li>
                            <li>{{ $t('modal.181') }}</li>
                            <li>{{ $t('modal.182') }}</li>
                            <li>{{ $t('modal.183') }}</li>
                        </ul>
                    </p>
                    <p></p>
                    <p></p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.184') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.185') }}</p>
                    <p>{{ $t('modal.186') }}</p>
                    <p>{{ $t('modal.187') }}</p>
                    <p>{{ $t('modal.188') }}</p>
                    <p>{{ $t('modal.189') }}</p>
                    <p>{{ $t('modal.190') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.191') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.192') }}</p>
                    <p>{{ $t('modal.193') }}</p>
                    <p>{{ $t('modal.194') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.195') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.196') }}</p>
                    <p>{{ $t('modal.197') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.198') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.199') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.200') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.201') }}</p>
                    <p>{{ $t('modal.202') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.203') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.204') }}</p>
                    <p>{{ $t('modal.205') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.206') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.207') }}</p>
                    <p>{{ $t('modal.208') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.209') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.210') }}</p>
                    <p>{{ $t('modal.211') }}</p>
                    <p>{{ $t('modal.212') }}</p>
                    <p>{{ $t('modal.213') }}</p>
                    <p>{{ $t('modal.214') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.215') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.216') }}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModalTerms'
    }
</script>

<style scoped>
    .modal-terms {
        width: 1000px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px 0 30px;
        border-radius: 15px;
        background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), 
                    linear-gradient(255deg, #07263d 0%, #07243a 100%);
    }

    .modal-terms .terms-header {
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid #335361;
    }

    .modal-terms .terms-header span  {
        font-size: 32px;
        font-weight: 900;
    }

    .modal-terms .terms-header p  {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-terms .terms-list {
        width: 100%;
        height: 600px;
        padding: 30px 20px 30px 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .modal-terms .terms-list::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .modal-terms .terms-list::-webkit-scrollbar-track {
        margin: 30px 0;
        background-color: #062234;
    }

    .modal-terms .terms-list::-webkit-scrollbar-thumb {
        background-color: #1e5164;
    }

    .modal-terms .list-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }

    .modal-terms .list-element:first-child {
        margin-top: 0;
    }

    .modal-terms .element-title {
        font-size: 24px;
        font-weight: 800;
        color: #ffffff;
    }

    .modal-terms .element-text {
        width: 100%;
        margin-top: 15px;
        padding-left: 30px;
    }

    .modal-terms .element-text p {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-terms .element-text p:first-child {
        margin-top: 0;
    }

    .modal-terms .element-text ul {
        margin-top: 15px;
        padding-left: 50px;
    }

    @media only screen and (max-width: 1190px) {

        .modal-terms {
            width: calc(100vw - 20px);
        }

    }

    @media only screen and (max-width: 700px) {

        .modal-terms {
            padding: 80px 15px 0 15px;
        }

    }
</style>
