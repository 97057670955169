const state = {
    isSpinModalOpen: false, // Spin modalının açık/kapalı durumu
    lastSpinTimestamp: null, // Kullanıcının son spin zamanı
    spinRewards: [], // Mevcut ödül listesi
  };
  
  const mutations = {
    SET_SPIN_MODAL(state, status) {
      state.isSpinModalOpen = status;
    },
    SET_LAST_SPIN_TIMESTAMP(state, timestamp) {
      state.lastSpinTimestamp = timestamp;
    },
    SET_SPIN_REWARDS(state, rewards) {
      state.spinRewards = rewards;
    },
  };
  
  const actions = {
    openSpinModal({ commit }) {
      commit('SET_SPIN_MODAL', true);
    },
    closeSpinModal({ commit }) {
      commit('SET_SPIN_MODAL', false);
    },
    setLastSpinTimestamp({ commit }, timestamp) {
      commit('SET_LAST_SPIN_TIMESTAMP', timestamp);
    },
    setSpinRewards({ commit }, rewards) {
      commit('SET_SPIN_REWARDS', rewards);
    },
  };
  
  const getters = {
    isSpinModalOpen: (state) => state.isSpinModalOpen,
    lastSpinTimestamp: (state) => state.lastSpinTimestamp,
    spinRewards: (state) => state.spinRewards,
  };
  
  export default {
    namespaced: true, // Modül isimlendirme desteği
    state,
    mutations,
    actions,
    getters,
  };
  