import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Çeviri dosyalarını içeri aktarın
import en from './en.json';
import tr from './tr.json';
import de from './de.json'; // Almanca
import pt from './pt.json'; // Portekizce
import fr from './fr.json'; // Fransızca
import it from './it.json'; // İtalyanca
import zh from './zh.json'; // Çince
import hi from './hi.json'; // Hintçe
import ru from './ru.json'; // Rusça
import id from './id.json'; // Endonezya
import es from './es.json'; // İspanyolca

Vue.use(VueI18n);

const savedLanguage = localStorage.getItem('language') || 'tr';

const i18n = new VueI18n({
    locale: savedLanguage, // Tarayıcıda kaydedilen dil veya varsayılan dil
    fallbackLocale: 'en', // Yedek dil
    messages: {
        en, // İngilizce
        tr, // Türkçe
        de, // Almanca
        pt, // Portekizce
        fr, // Fransızca
        it, // İtalyanca
        zh, // Çince
        hi, // Hintçe
        ru, // Rusça
        id, // Endonezya
        es, // İspanyolca
    },
});

// Dil değiştirildiğinde `localStorage` güncellemesi
Vue.prototype.$changeLanguage = function (lang) {
    i18n.locale = lang;
    localStorage.setItem('language', lang);
};

export default i18n;
