<template>
    <div class="modal-faq">
        <div class="faq-header">
            <span class="gradient-green">{{ $t('modal.78') }}</span>
            <p>{{ $t('modal.79') }}</p>
        </div>
        <div class="faq-list">
            <FaqElement
                v-for="(faq, index) in faqItems"
                :key="index"
                :title="$t(faq.title)"
                :text="$t(faq.text)"
            />
        </div>
    </div>
</template>

<script>
import FaqElement from '@/components/faq/FaqElement';

export default {
    name: 'ModalFaq',
    components: {
        FaqElement,
    },
    data() {
        return {
            faqItems: [
                { title: 'faq.whatIsRblxRoll', text: 'faq.whatIsRblxRollDescription' },
                { title: 'faq.whyCredentials', text: 'faq.whyCredentialsDescription' },
                { title: 'faq.whatAreCoins', text: 'faq.whatAreCoinsDescription' },
                { title: 'faq.transferCoins', text: 'faq.transferCoinsDescription' },
                { title: 'faq.missingCoins', text: 'faq.missingCoinsDescription' },
                { title: 'faq.cryptoDeposit', text: 'faq.cryptoDepositDescription' },
                { title: 'faq.vulnerability', text: 'faq.vulnerabilityDescription' },
                { title: 'faq.rainAbuse', text: 'faq.rainAbuseDescription' },
                { title: 'faq.commission', text: 'faq.commissionDescription' },
                { title: 'faq.depositWithdraw', text: 'faq.depositWithdrawDescription' },
                { title: 'faq.maxBet', text: 'faq.maxBetDescription' },
                { title: 'faq.internetCrash', text: 'faq.internetCrashDescription' },
                { title: 'faq.maxMultiplier', text: 'faq.maxMultiplierDescription' },
                { title: 'faq.fairResults', text: 'faq.fairResultsDescription' },
                { title: 'faq.promotingWebsite', text: 'faq.promotingWebsiteDescription' },
            ],
        };
    },
};
</script>


<style scoped>
    .modal-faq {
        width: 1000px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px 0 30px;
        border-radius: 15px;
        background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), 
                    linear-gradient(255deg, #07263d 0%, #07243a 100%);
    }

    .modal-faq .faq-header {
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid #335361;
    }

    .modal-faq .faq-header span  {
        font-size: 32px;
        font-weight: 900;
    }

    .modal-faq .faq-header p  {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-faq .faq-list {
        width: 100%;
        height: 600px;
        padding: 30px 10px 30px 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .modal-faq .faq-list::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .modal-faq .faq-list::-webkit-scrollbar-track {
        margin: 30px 0;
        background-color: #062234;
    }

    .modal-faq .faq-list::-webkit-scrollbar-thumb {
        background-color: #1e5164;
    }

    @media only screen and (max-width: 1190px) {

        .modal-faq {
            width: calc(100vw - 20px);
        }

    }

    @media only screen and (max-width: 700px) {

        .modal-faq {
            padding: 80px 15px 0 15px;
        }

    }
</style>
