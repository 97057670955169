<template>
    <div class="modal-spin">
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-content">
        <div class="spin-wheel-container">
          <img
            src="@/assets/img/spin/spin-background.png"
            alt="Spin Background"
            class="spin-background"
          />
          <div
            class="spin-wheel"
            :style="{ transform: 'rotate(' + wheelRotation + 'deg)' }"
          >
            <div
              v-for="(segment, index) in segments"
              :key="index"
              :style="getSegmentStyle(index)"
              class="wheel-segment"
            >
              <span>{{ segment }}</span>
            </div>
          </div>
          <img
            src="@/assets/img/spin/spin-btn.png"
            alt="Spin Button"
            class="spin-btn"
            @click="spinWheel"
          />
          <img
            src="@/assets/img/spin/spin-point.png"
            alt="Spin Point"
            class="spin-point"
          />
          <div
            v-for="n in 16"
            :key="n"
            :class="['spin-light', { 'active-light': hoveredLight === n }]"
            :data-index="n"
            @mouseover="hoveredLight = n"
            @mouseleave="hoveredLight = null"
          ></div>
        </div>
        <div v-if="prize" class="prize-display">
          Tebrikler! Kazandınız: {{ prize }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        wheelRotation: 0,
        segments: [
          '5 TL',
          '10 TL',
          '25 TL',
          '50 TL',
          '100 TL',
          '250 TL',
          '500 TL',
          '1000 TL',
          '1500 TL',
          '2000 TL',
        ],
        prize: null,
        hoveredLight: null,
      };
    },
    computed: {
      segmentAngle() {
        return 360 / this.segments.length;
      },
    },
    methods: {
      getSegmentStyle(index) {
        const segmentAngle = 360 / this.segments.length;
        const colors = ['#FF0000', '#FFA500', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#800080', '#FF1493'];
        return {
          transform: `rotate(${index * segmentAngle}deg)`,
          background: colors[index % colors.length],
          clipPath: 'polygon(50% 50%, 100% 0, 100% 100%)',
        };
      },
      closeModal() {
        this.$emit('close');
      },
      spinWheel() {
        const randomAngle = Math.floor(Math.random() * 360);
        this.wheelRotation += 360 * 3 + randomAngle;
        const winningIndex =
          Math.floor((360 - (this.wheelRotation % 360)) / this.segmentAngle) %
          this.segments.length;
        this.prize = this.segments[winningIndex];
        setTimeout(() => {
          alert(`Tebrikler! Kazandınız: ${this.prize}`);
          this.closeModal();
        }, 3000);
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-spin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    position: relative;
    text-align: center;
  }
  
  .spin-wheel-container {
    position: relative;
    width: 400px;
    height: 400px;
    margin: 0 auto;
  }
  
  .spin-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .spin-wheel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transition: transform 3s ease-out;
    z-index: 2;
  }
  
  .wheel-segment {
    position: absolute;
    width: 50%;
    height: 50%;
    transform-origin: 100% 100%;
    clip-path: polygon(50% 50%, 100% 0, 100% 100%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
  
  .spin-btn {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    cursor: pointer;
  }
  
  .spin-point {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
  }
  
  .spin-light {
    position: absolute;
    width: 20px;
    height: 20px;
    background: yellow;
    border-radius: 50%;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) scale(1);
    transition: transform 0.2s;
  }
  
  .spin-light.active-light {
    transform: translateX(-50%) scale(1.5);
  }
  
  .prize-display {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #4caf50;
  }
  </style>
  