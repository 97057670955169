<template>
    <div v-if="showModal" class="modal-overlay" @click.self="toggleModal">
      <div class="modal-content">
        <button @click="toggleModal" class="close-button">×</button>
        <h2>{{ $t('modal.87') }}</h2>
  
        <!-- Yükleniyor Göstergesi -->
        <p v-if="loading" class="loading-message">{{ $t('modal.88') }}</p>
  
        <!-- Hata Mesajı -->
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  
        <!-- Bildirim Listesi -->
        <ul v-if="!loading && notifications.length" class="notification-list">
          <li v-for="notification in notifications" :key="notification._id" class="notification-item">
            <h3>{{ notification.title }}</h3>
            <p>{{ notification.message }}</p>
            <span class="notification-date">{{ formatDate(notification.createdAt) }}</span>
          </li>
        </ul>
  
        <!-- Bildirim Yok Mesajı -->
        <p v-else-if="!loading && !notifications.length" class="no-notifications">
           {{ $t('modal.89') }}
        </p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: {
      showModal: {
        type: Boolean,
        required: true
      },
      userId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        notifications: [], // Bildirimler burada tutulacak
        loading: false, // Yüklenme durumu
        errorMessage: '' // Hata mesajı
      };
    },
    watch: {
      showModal(newValue) {
        if (newValue) {
          this.fetchNotifications(); // Modal açıldığında bildirimleri çek
        }
      }
    },
    methods: {
  toggleModal() {
    this.$emit('close'); // Modalı kapatmak için parent bileşene close event'i gönder
  },
  async fetchNotifications() {
    this.loading = true;
    this.errorMessage = '';
    console.log('Kullanıcı ID:', this.userId); // Kullanıcı ID'sini kontrol et
    try {
      const response = await axios.get(`https://play.rivobit.com/notices/${this.userId}`);
      console.log('API Yanıtı:', response.data); // API yanıtını logla
      this.notifications = response.data;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      this.errorMessage = 'Bildirimler yüklenirken bir hata oluştu.';
    } finally {
      this.loading = false;
    }
  },
  formatDate(date) {
    return new Date(date).toLocaleString();
  }
}
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Arka planı karart */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #ffffff;
    border-radius: 16px;
    padding: 40px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideDown 0.4s ease;
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #6c757d;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #000000;
  }
  
  h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  .notification-list {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .notification-item {
    background-color: #f9fafb;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  }
  
  .notification-item:hover {
    background-color: #f1f3f5;
  }
  
  .notification-item h3 {
    margin: 0;
    font-size: 18px;
    color: #007bff;
  }
  
  .notification-item p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }
  
  .notification-date {
    font-size: 12px;
    color: #888;
  }
  
  .loading-message {
    font-size: 16px;
    color: #555;
    text-align: center;
  }
  
  .error-message {
    font-size: 16px;
    color: red;
    text-align: center;
  }
  
  .no-notifications {
    font-size: 16px;
    color: #555;
    text-align: center;
  }
  </style>
  