<template>
    <div v-if="isJackpotModalOpen" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <!-- Modal Title -->
        <h2>Rivo JACKPOT</h2>
  
        <!-- Banner Component -->
        <Bannerjackpot />
  
        <!-- Jackpot Description -->
        <p>
          {{ $t('modal.80') }}
        </p>
  
        <!-- Close Button -->
        <button class="modal-close" @click="closeModal">{{ $t('modal.81') }}</button>
      </div>
    </div>
  </template>
  
  <script>
  import Bannerjackpot from '@/components/banner/BannerJackpot.vue';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'ModalJackpot',
    components: {
      Bannerjackpot,
    },
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
    ...mapGetters(['isJackpotModalOpen']), // Vuex getter'ı kullanarak modal görünürlüğünü kontrol et
  },
    methods: {
        ...mapActions(['closeJackpotModal']),
      closeModal() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 9999;
  }
  
  .modal-content {
    background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, #07263d 0%, #07243a 100%);
    padding: 20px;
    border-radius: 15px;
    width: 400px;
    text-align: center;
  }
  
  .modal-content h2 {
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }
  
  .modal-content p {
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
  }
  
  .modal-close {
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 900;
    margin-top: 20px;
  }
  </style>
  