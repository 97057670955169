import { render, staticRenderFns } from "./DuelsGameFair.vue?vue&type=template&id=327436e8&scoped=true"
import script from "./DuelsGameFair.vue?vue&type=script&lang=js"
export * from "./DuelsGameFair.vue?vue&type=script&lang=js"
import style0 from "./DuelsGameFair.vue?vue&type=style&index=0&id=327436e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327436e8",
  null
  
)

export default component.exports