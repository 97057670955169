<template>
    <div v-if="isVisible" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img :src="imageSrc" alt="Modal Image" class="modal-image" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ModalLisance",
    props: {
      isVisible: {
        type: Boolean,
        required: true,
      },
      imageSrc: {
        type: String,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 80vh;
    display: block;
  }
  </style>
  