<template>
    <div class="rewards">
      <div class="rewards-banner">
        <RewardsCode />
        <RewardsRakeback />
      </div>
  
      <div class="rewards-boxes">
        <div class="boxes-title">{{ $t('mainte.22') }}</div>
        <div class="boxes-content">
          <transition name="fade" mode="out-in">
            <div v-if="rakebackData.loading === true" class="content-loading" key="loading">
              <div class="loading-placeholder"></div>
              <div class="loading-placeholder"></div>
              <div class="loading-placeholder"></div>
              <div class="loading-placeholder"></div>
              <div class="loading-placeholder"></div>
              <div class="loading-placeholder"></div>
              <div class="loading-placeholder"></div>
            </div>
            <div v-else-if="rakebackData.boxes.length > 0" class="content-list" key="data">
              <RewardsBoxElement v-for="box of rakebackData.boxes" v-bind:key="box._id" v-bind:box="box" />
            </div>
            <div v-else class="content-empty" key="empty">{{ $t('mainte.23') }}</div>
          </transition>
        <!-- Spin Button -->
        
      </div>
    </div>

  </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import RewardsCode from '@/components/rewards/RewardsCode';
  import RewardsRakeback from '@/components/rewards/RewardsRakeback';
  import RewardsBoxElement from '@/components/rewards/RewardsBoxElement';
  import ModalSpin from '@/components/modals/ModalSpin.vue';

  
  export default {
    name: 'Rewards',
    components: {
      RewardsCode,
      RewardsRakeback,
      RewardsBoxElement,
      ModalSpin,
    },
    data() {
      return {
        selectedCategory: 'all',
        
        bonuses: [
          { id: 1, title: 'Welcome Bonus', category: 'casino', description: 'Get a 100% bonus on your first deposit!', image: require('@/assets/img/promotions/1.webp') },
          { id: 2, title: 'Free Spins Bonus', category: 'casino', description: 'Get free spins on select slot games!', image: require('@/assets/img/promotions/1.webp') },
          { id: 3, title: 'Live Casino Welcome Bonus', category: 'live-casino', description: 'Get a special bonus for live casino games!', image: require('@/assets/img/promotions/1.webp') },
          { id: 4, title: 'Live Casino Cashback', category: 'live-casino', description: 'Receive cashback on your live casino losses!', image: require('@/assets/img/promotions/1.webp') },
          { id: 5, title: 'Sports Welcome Bonus', category: 'sports', description: 'Get a 100% bonus on your first sports bet!', image: require('@/assets/img/promotions/1.webp') },
          { id: 6, title: 'Sports Free Bet Bonus', category: 'sports', description: 'Get a free bet on selected sports events!', image: require('@/assets/img/promotions/1.webp') },
          { id: 7, title: 'Crypto Deposit Bonus', category: 'crypto', description: 'Get a 50% bonus when depositing with crypto!', image: require('@/assets/img/promotions/1.webp') },
          { id: 8, title: 'Crypto Cashback Bonus', category: 'crypto', description: 'Receive cashback on your crypto bets!', image: require('@/assets/img/promotions/1.webp') }
        ],
        filteredBonuses: [],
        claimedBonuses: [
          { id: 1, user: 'JohnDoe', bonus: 'Welcome Bonus', date: '2024-10-10' },
          { id: 2, user: 'JaneSmith', bonus: 'Crypto Deposit Bonus', date: '2024-10-11' },
          { id: 3, user: 'MikeLee', bonus: 'Free Spins Bonus', date: '2024-10-12' }
        ]
      };
    },
    methods: {
      openSpinModal() {
    this.isSpinModalOpen = true;
  },
      ...mapActions([
        'rakebackGetDataSocket',
      ]),
      ...mapActions('spin', ['openSpinModal', 'closeSpinModal']),
      filterBonuses(category) {
        this.selectedCategory = category;
        if (category === 'all') {
          this.filteredBonuses = this.bonuses;
        } else {
          this.filteredBonuses = this.bonuses.filter(bonus => bonus.category === category);
        }
      },
      claimBonus(bonus) {
        const newClaim = {
          id: this.claimedBonuses.length + 1,
          user: 'TestUser',
          bonus: bonus.title,
          date: new Date().toISOString().split('T')[0]
        };
        this.claimedBonuses.push(newClaim);
      }
    },
    computed: {
      ...mapGetters([
        'rakebackData',
       
      ]),
      ...mapGetters('spin', ['isSpinModalOpen']),
    },
    created() {
      if (this.rakebackData.loading === false) {
        const data = {};
        this.rakebackGetDataSocket(data);
      }
      this.filteredBonuses = this.bonuses; // Show all bonuses initially
    }
  };
  </script>
<style scoped>
    .rewards {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 10px;
    }

    .rewards .rewards-banner {
        width: 100%;
        display: flex;
        font-family: Rubik;
    }

    .rewards .rewards-boxes {
        width: 100%;
        position: relative;
        margin-top: 66px;
    }

    .rewards .rewards-boxes:before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #f8a216 50%, rgba(2, 25, 44, 0) 100%);
    }

    .rewards .boxes-title {
        width: 220px;
        height: 40px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);
        font-family: Rubik;
        font-size: 20px;
        font-weight: 900;
        color: #ffffff;
        background: #fca311;
        clip-path: polygon(7px 0, calc(100% - 7px) 0, 100% 25%, 100% 75%, calc(100% - 7px) 100%, 7px 100%, 0 75%, 0 25%);
    }

    .rewards .boxes-content {
        width: 100%;
        margin-top: 63px;
    }

    .rewards .content-loading,
    .rewards .content-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .rewards .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .rewards .content-loading.fade-leave-to {
        opacity: 0;
    }

    .rewards .loading-placeholder {
        width: calc(14.28% - 7.7px);
        height: 220px;
        position: relative;
        margin-bottom: 10px;
        margin-right: 9px;
        background: linear-gradient(223deg, rgba(5, 29, 48, 0.35) 0%, rgba(31, 99, 153, 0.09) 50%, rgba(5, 29, 48, 0.35) 100%);
        border: 1px solid #0a273f;
        overflow: hidden;
    }

    .rewards .loading-placeholder:after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, 0.1) 50%, #ffffff00 100%);
    }

    .rewards .loading-placeholder:nth-child(7n) {
        margin-right: 0;
    }

    .rewards .content-empty {
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }


    .rewards .content-list.fade-enter-active,
    .rewards .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .rewards .content-list.fade-enter-from,
    .rewards .content-empty.fade-enter-from {
        opacity: 0;
    }

 /* Bonus Section Styles */
.bonus-section {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}

/* Filter Container */
.filter-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-container button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  height: 2.3125rem;
  border-radius: 13px;
  border: 1px solid #1b90d54f;
  background: linear-gradient(100deg, rgba(0, 255, 194, 0.1) 5%, rgba(0, 255, 194, 0.07) 30%, rgba(0, 255, 194, 0.06) 50%, rgba(0, 0, 0, 0) 80%), rgba(3, 16, 27, 0.32);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-container button span {
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 900;
  background: linear-gradient(180deg, #ffffff 1.75%, rgba(108, 255, 211, 0.85) 35%, rgba(0, 255, 71, 0.55) 70.93%, #7bff90 95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  filter: drop-shadow(0px 0px 10px rgba(255, 10, 10, 0.25));
}

/* Bonus Cards Grid */
.bonus-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

/* Bonus Card Styles */
.bonus-card {
  background-color: #1f233073;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.bonus-card:hover {
  transform: scale(1.05);
}

.bonus-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.bonus-card h2 {
  margin: 15px 0;
  font-size: 1.5rem;
  font-family: 'Rubik', sans-serif;
  background: linear-gradient(180deg, #ffffff 1.75%, rgba(108, 255, 211, 0.85) 35%, rgba(0, 255, 71, 0.55) 70.93%, #7bff90 95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bonus-card p {
  margin: 15px 0;
  font-size: 1rem;
  color: #ccc;
}

.claim-btn {
  background-color: #00ffc2;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.claim-btn:hover {
  background-color: #00aa6d;
}

/* Claimed Bonuses Table */
.claimed-bonuses-table {
  margin-top: 40px;
}

.claimed-bonuses-table h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.claimed-bonuses-table table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.claimed-bonuses-table th, .claimed-bonuses-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.claimed-bonuses-table th {
  background-color: #1f2330;
  color: white;
}

.claimed-bonuses-table td {
  background-color: #2a2d3b;
  color: white;
}

.promotions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));  /* Default for larger screens */
    gap: 20px;
  }
  
  /* Two columns on smaller screens (mobile) */
  @media (max-width: 768px) {
    .promotions-grid {
      grid-template-columns: repeat(2, 1fr); /* Two columns on mobile */
    }
  }

    @keyframes loading_animation {
        0% { transform: translateX(-100%); }
        50% { transform: translateX(100%); }
        100% { transform: translateX(100%); }
    }

    @media only screen and (max-width: 1270px) {

        .rewards .rewards-banner {
            width: 100%;
        }

        .rewards .rewards-boxes {
            width: 100%;
        }

    }

    @media only screen and (max-width: 1150px) {

        .rewards .rewards-banner {
            flex-direction: column;
        }

    }
</style>