<template>
    <div class="promotions-page">
     
  
      <!-- Promotions Grid -->
      <div class="promotions-grid">
        <div class="promotion-card" v-for="promo in filteredPromotions" :key="promo.id">
          <img :src="promo.image" alt="promo.title" class="promo-image">
          <h2>{{ promo.title }}</h2>
          <p>{{ promo.description }}</p>
          <button class="detail-btn" @click="openDetail(promo)">{{ $t('mainte.13') }}</button>
        </div>
      </div>
  
      <!-- Modal for both PC and Mobile -->
      <div v-if="showModal" class="modal" @click="closeModal">
        <div class="modal-content" @click.stop>
          <img :src="selectedPromotion.image" alt="selectedPromotion.title" class="modal-promo-image">
          <h2>{{ selectedPromotion.title }}</h2>
          <p>{{ selectedPromotion.modalDescription }}</p>
          <button class="modal-close" @click="closeModal">{{ $t('mainte.14') }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        selectedCategory: 'all',
        promotions: [], // Dinamik veri
        filteredPromotions: [],
        showModal: false,
        selectedPromotion: {}
      };
    },
    mounted() {
      this.fetchPromotions(); // Komponent yüklendiğinde bonusları çek
    },
    methods: {
      async fetchPromotions() {
        try {
          const response = await axios.get("https://play.rivobit.com/bonus");
          this.promotions = response.data.map((bonus) => ({
            id: bonus._id,
            title: bonus.title,
            description: bonus.description,
            modalDescription: bonus.modalDescription,
            category: bonus.bonusType, // Bonus tipi kategori olarak kullanılıyor
            image: bonus.img
          }));
          this.filterPromotions(this.selectedCategory); // Filtreleri güncelle
        } catch (error) {
          console.error("Bonuslar alınırken hata oluştu:", error);
        }
      },
      filterPromotions(category) {
        this.selectedCategory = category;
        this.filteredPromotions =
          category === 'all'
            ? this.promotions
            : this.promotions.filter(promo => promo.category === category);
      },
      openDetail(promo) {
        this.selectedPromotion = promo;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    }
  };
  </script>
  
  <style scoped>
  /* General Styles */
  .promotions-page {
   
    color: white;
  }
  
  .filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filter-container button span {
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 900;
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  filter: drop-shadow(0px 0px 10px rgba(255, 10, 10, 0.25));
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.filter-container button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  height: 2.3125rem;
  border-radius: 13px;
  border: 1px solid #1b90d54f;
  background: linear-gradient(100deg, rgba(0, 255, 194, 0.1) 5%, rgba(0, 255, 194, 0.07) 30%, rgba(0, 255, 194, 0.06) 50%, rgba(0, 0, 0, 0) 80%), rgba(3, 16, 27, 0.32);
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
  
 
  /* Promotions Grid */
  .promotions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));  /* Default for larger screens */
    gap: 20px;
    margin-bottom: 40px;
  }
  
  /* Two columns on smaller screens (mobile) */
  @media (max-width: 768px) {
    .promotions-grid {
      grid-template-columns: repeat(2, 1fr); /* Two columns on mobile */
    }
  }
  
  .promotion-card {
    background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
    background-color: #090f1e;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    text-align: center;
  }
  
  .promotion-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .promotion-card h2 {
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 600;
  color: #00ffc2;
  filter: drop-shadow(0px 0px 10px rgba(255, 10, 10, 0.25));
}

  .promotion-card p {
    margin: 15px 0;
    
    font-family: 'Rubik';
  font-size: 12px;
  font-weight: 600;
    background:  linear-gradient(180deg, #ffffff 1.75%, rgba(108, 255, 211, 0.85) 35%, rgba(0, 255, 71, 0.55) 70.93%, #7bff90 95%);
    background-clip: text;
  }
  
  .detail-btn {
    background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
    color: white;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  
  /* Modal Styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.26);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
  }
  
  .modal-content {
    background: radial-gradient(100% 100% at 50% -30%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, #07263d 0%, #07243a 100%);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 90%;
    max-width: 500px;
  }

  .modal-content h2 {
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .modal-promo-image {
    width: 100%;
    border-radius: 10px;
  }
  
  .modal-close {
    background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    color: white;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  </style>
  