<template>
    <div class="modal-login">
        <div class="login-sidebar">
            <img src="@/assets/img/logo_small.png" />
            <div class="sidebar-text">
                <span class="gradient-green">{{ $t('modal.82') }}</span>
            </div>
            <img v-if="['login', 'register', 'roblox login'].includes(modalTab) === true" src="@/assets/img/knight.png" />
        </div>
        <div class="login-content">
            <div class="content-title">
                <span class="gradient-green">{{ modalTab === 'forgot' ? 'RESET PASSWORD' : modalTab.toUpperCase() }}</span>
            </div>
            <div v-if="['login', 'register', 'roblox login', 'roblox cookie'].includes(modalTab) === true" class="content-auth">
                <LoginRoblox v-if="['roblox login', 'roblox cookie'].includes(modalTab) === true" v-bind:tab="modalTab" />
                <LoginCredentials v-else v-bind:tab="modalTab" />

                <div class="auth-seperator">{{ $t('modal.83') }}</div>

                <!-- Telegram ile giriş -->
                <button class="button-auth telegram-auth">
                    <div class="button-inner">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.954 4.569c-.885-.654-2.05-.653-2.95-.002L2.17 13.09c-.993.643-1.584 1.566-1.571 2.5.013.932.64 1.786 1.6 2.283l3.56 1.78 1.785 5.07c.458 1.3 1.352 1.44 2.07.97l3.98-2.22 3.97 1.84c.716.33 1.557.185 2.096-.373.537-.56.66-1.42.327-2.13L22.81 5.37c-.33-.71-.983-1.26-1.7-1.48-.715-.224-1.496-.104-2.156.36z" fill="white"/>
                        </svg>
                        {{ $t('modal.84') }}
                    </div>
                </button>

                <!-- Google ile giriş -->
                <button class="button-auth google-auth">
                    <div class="button-inner">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.705 12.276c0-.805-.072-1.408-.227-2.026H12v3.81h6.505c-.132 1.05-.845 2.64-2.426 3.7l-.023.154 3.524 2.758.244.024c2.228-2.06 3.88-5.086 3.88-8.42z" fill="#4285F4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24c3.24 0 5.953-1.072 7.937-2.91l-3.78-2.95c-1.018.702-2.358 1.193-4.157 1.193-3.18 0-5.883-2.07-6.85-4.93l-.142.012-3.692 2.885-.048.137C2.94 21.65 7.15 24 12 24z" fill="#34A853"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.15 14.403A6.924 6.924 0 014.816 12c0-.836.154-1.64.323-2.403l-.005-.16-3.726-2.92-.122.06C.392 8.66 0 10.276 0 12c0 1.732.392 3.343 1.283 4.473L5.15 14.403z" fill="#FBBC05"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.81c1.898 0 3.182.82 3.913 1.503l2.857-2.797C16.667 1.54 14.53 0 12 0 7.15 0 2.94 2.35.66 5.53L4.7 8.31C5.7 5.98 8.42 4.81 12 4.81z" fill="#EA4335"/>
                        </svg>
                        {{ $t('modal.85') }}
                    </div>
                </button>

                <!-- Yandex ile giriş -->
                <button class="button-auth yandex-auth">
                    <div class="button-inner">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 0L7.78 8.57l-1.85-4.19H0v1.38h4.4l4.13 9.62H7.3L3.96 9.08l-1.86.97L5.73 24h2.15l6.13-17.78L15.82 24h2.36l5.16-13.64L23 9.08l-1.36-.58-4.57 12.24h-.15L11.42 0h-.67z" fill="white"/>
                        </svg>
                       {{ $t('modal.86') }}
                    </div>
                </button>

                <button v-if="['roblox login', 'roblox cookie'].includes(modalTab) === true" v-on:click="modalSetTab('login')" class="button-credentials">
                    <div class="button-inner">Sign in with Email</div>
                </button>
                
            </div>
            <LoginForgot v-else />
        </div>
    </div>
</template>


<script>
    import { mapActions } from 'vuex';
    import LoginCredentials from '@/components/login/LoginCredentials';
    import LoginRoblox from '@/components/login/LoginRoblox';
    import LoginForgot from '@/components/login/LoginForgot';

    export default {
        name: 'ModalLogin',
        components: {
            LoginCredentials,
            LoginRoblox,
            LoginForgot
        },  
        data() {
            return {
                modalTab: 'login'
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow'
            ]),
            modalSetTab(tab) {
                this.modalTab = tab;
            },
            modalTermsButton() {
                this.modalsSetShow(null);
                setTimeout(() => { this.modalsSetShow('Terms'); }, 300);
            }
        }
    }
</script>

<style scoped>
    .modal-login {
        width: 1010px;
        border-radius: 15px;
        background: radial-gradient(100% 100% at 50% -31.45%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, #07263d 0%, #07243a 100%);
    }

    .modal-login .login-sidebar {
        width: 237px;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        left: 0;
        border-radius: 15px 0 0 15px;
        background: radial-gradient(225% 75% at 50% 50%, rgba(21, 71, 98, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #051e2e;
    }

    .modal-login .login-sidebar img:nth-of-type(1) {
        width: 143px;
        margin-top: 45px;
    }

    .modal-login .login-sidebar img:nth-of-type(2) {
        position: absolute;
        bottom: -45px;
    }

    .modal-login .sidebar-text {
        width: 100%;
        position: relative;
        margin-top: 42px;
        padding: 15px 30px;
        text-align: center;
        background: linear-gradient(255deg, rgba(0, 255, 194, 0.05) 0%, rgba(0, 170, 109, 0.05) 100%);
    }

    .modal-login .sidebar-text::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(255deg, rgba(0, 255, 194, 0.5) 0%, rgba(0, 170, 109, 0.5) 100%), #18486d;
    }

    .modal-login .sidebar-text::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(255deg, rgba(0, 255, 194, 0.5) 0%, rgba(0, 170, 109, 0.5) 100%), #18486d;
    }

    .modal-login .sidebar-text span {
        font-family: 'Rubik';
        line-height: 28px;
        font-size: 18px;
        font-weight: 900;
        text-shadow: 0px 0px 50px rgba(0, 255, 194, 0.25);
    }

    .modal-login .login-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px 110px 48px 347px;
    }

    .modal-login .content-title {
        text-align: center;
        font-size: 32px;
        font-weight: 900;
    }

    .modal-login .content-auth {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }

    .modal-login .auth-seperator {
        width: 530px;
        position: relative;
        margin-top: 35px;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: #5e768e;
    }

    .modal-login .auth-seperator:before,
    .modal-login .auth-seperator:after {
        content: '';
        width: calc(50% - 110px);
        height: 1px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        background: #18486d;
        opacity: 0.5;
    }

    .modal-login .auth-seperator:before {
        left: 0;
    }

    .modal-login .auth-seperator:after {
        right: 0;
    }

    .modal-login button.button-credentials,
    .modal-login button.button-roblox {
        width: 100%; 
        height: 54px;
        margin-top: 35px;
    }

    .modal-login button.button-credentials .button-inner,
    .modal-login button.button-roblox .button-inner {
        width: 100%; 
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        background: #1a4f63;
        clip-path: polygon(11px 0, calc(100% - 11px) 0, 100% 25%, 100% 75%, calc(100% - 11px) 100%, 11px 100%, 0 75%, 0 25%);
    }

    .modal-login button.button-roblox .button-inner {
        background: #4178ca;
    }

    .modal-login button.button-roblox .button-inner svg {
        margin-right: 14px;
        fill: #ffffff;
    }

    @media only screen and (max-width: 1030px) {

        .modal-login {
            width: calc(100vw - 20px);
        }

    }

    @media only screen and (max-width: 950px) {

        .modal-login .login-sidebar {
            display: none;
        }

        .modal-login .login-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15px 15px 48px 15px;
        }

    }

    @media only screen and (max-width: 560px) {

        .modal-login .auth-seperator {
            width: 100%;
        }

    }

    .modal-login .button-auth {
    width: 100%;
    height: 54px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a4f63;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.modal-login .button-auth:hover {
    background-color: #0d3b4d;
}

.modal-login .button-auth .button-inner {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
}

.modal-login .button-auth svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.modal-login .telegram-auth {
    background-color: #0088cc;
}

.modal-login .google-auth {
    background-color: #db4437;
}

.modal-login .yandex-auth {
    background-color: #ffcc00;
}

</style>
