<template>
    <div class="home">

        <div class="top-banner">
            <!-- Buraya banner içeriği eklenecek -->
            <img src="@/assets/img/banner/bonus2.png" alt="Top Banner" />
        </div>

       
       
        <div class="home-grid">
    <div
      v-for="(item, index) in gridItems"
      :key="index"
      class="grid-item"
      @click="navigateTo(item.link)"
    >
    
    <img :src="item.image" :alt="item.alt" />
      
    </div>
  </div>

        <div class="buy-crypto">
            <h2 class="buy-crypto-title">{{ $t('home.cryptotitle') }}</h2>
            <button class="buy-crypto-button">{{ $t('home.buybutton') }}</button>
        </div>

        
            <div class="vertical-grid">
    <div
      v-for="(item, index) in verticalItems"
      :key="index"
      class="vertical-item"
      @click="navigateTo(item.link)"
    >
      <div class="image-wrapper">
        <img :src="item.image" :alt="item.alt" />
        
      </div>
    </div>
  </div>

        <HomeDepositMethods />
    
    </div>
</template>

<script>
    import Bets from '@/components/bets/Bets';
    import HomeBannerUser from '@/components/home/HomeBannerUser';
    import HomeBannerNews from '@/components/home/HomeBannerNews'
    import HomeFeaturedLink from '@/components/home/HomeFeaturedLink';
    import HomeGameElement from '@/components/home/HomeGameElement';
    import HomePlaceholderElement from '@/components/home/HomePlaceholderElement';
    import HomeDepositMethods from '@/components/home/HomeDepositMethods';

    export default {
        name: 'Home',
        metaInfo: {
            title: 'Rivobit: The Innovative Crypto Casino Site'
        },
        components: {
            Bets,
            HomeBannerUser,
            HomeBannerNews,
            HomeFeaturedLink,
            HomeGameElement,
            HomePlaceholderElement,
            HomeDepositMethods
        },
        data() {
    return {
        gridItems: [
        {
          image: require('@/assets/img/banner/1.png'),
          alt: 'Banner 1',
          title: 'Casino',
          link: '/slot',
        },
        {
          image: require('@/assets/img/banner/2.png'),
          alt: 'Banner 2',
          title: 'Sport' ,
          link: '/sport',
        },
        {
          image: require('@/assets/img/banner/3.png'),
          alt: 'Banner 3',
          title: 'Rivo Originals',
          link: '/leaderboard',
        },
        {
          image: require('@/assets/img/banner/4.png'),
          alt: 'Banner 4',
          title: 'Sports',
          
          link: '/affiliates',
        },
        
      ],
      verticalItems: [
        {
          image: require('@/assets/img/home/1.png'),
          alt: 'Vertical Banner 1',
          
          link: '/rewards',
        },
        {
          image: require('@/assets/img/home/2.png'),
          alt: 'Vertical Banner 2',
         
          link: '/rewards',
        },
        {
          image: require('@/assets/img/home/3.png'),
          alt: 'Vertical Banner 3',
         
          link: '/affiliates',
        },
        {
          image: require('@/assets/img/home/4.png'),
          alt: 'Vertical Banner 4',
          
          link: '/promotions',
        },
      ],
    };
  },
  methods: {
    navigateTo(link) {
      // Yönlendirme için Vue Router kullanıyorsanız
      this.$router.push(link);

      // Eğer sayfa yeniden yüklenecekse:
      // window.location.href = link;
    },
  },
    }
</script>

<style scoped>
    .home {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    
    
        
    }

    .home .home-banner {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
    background-color: #090f1e;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);

    }

    .top-banner {
        width: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px; /* Alt boşluk */
        
    }

    .top-banner img {
        width: 100%;
        height: auto;
        border-radius: 25px;
        background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
    background-color: #090f1e;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
    }

    .home-grid {
        width: 100%;
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* Masaüstü için 4 sütun */
        gap: 20px; /* Elemanlar arası boşluk */
        margin: 20px 0;
    }



   .grid-item {
  cursor: pointer;
  text-align: center;
}

.grid-item img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
  background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
    background-color: #090f1e;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
}

.image-wrapper {
  position: relative;
 
  border-radius: 8px;
  overflow: hidden;
}

.image-wrapper img {
  max-width: 100%;
  height: auto;
  display: block;
  background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
    background-color: #090f1e;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
}

.banner-title {
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1em;
  border-radius: 4px;
  z-index: 2;
}

    @media only screen and (max-width: 768px) {
        .home-grid {
            grid-template-columns: repeat(2, 1fr); /* Mobil için 2 sütun */
        }
    }
    .vertical-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* PC için 4 sütun */
  gap: 20px;
  width: 100%;
}

.vertical-item {
  position: relative;
  cursor: pointer;
  
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.vertical-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-wrapper {
  position: relative;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
}



/* Mobil Cihazlar */
@media screen and (max-width: 768px) {
  .vertical-grid {
    grid-template-columns: repeat(2, 1fr); /* Mobilde 2 sütun */
  }
}

    .buy-crypto {
        width: 100%;
        
        margin: 30px 0;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
    background-color: #090f1e;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
        border-radius: 8px; /* Köşeleri yuvarlama */
    }

    .buy-crypto-title {
        font-size: 20px;
        font-weight: 600;
        color: #fff; /* Başlık rengi */
    }

    .buy-crypto-button {
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background: linear-gradient(255deg, #00ffc2 20%, #00aa6d 70%);
        box-shadow: 0 3px 20px #00ffc2, inset 0 4px 4px #ffffff5e;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .buy-crypto-button:hover {
        background-color: #0056b3; /* Hover rengi */
    }

    @media only screen and (max-width: 768px) {
        .buy-crypto {
            flex-direction: column;
            text-align: center;
        }

        .buy-crypto-button {
            margin-top: 10px;
        }
    }

    .home .home-featured,
    .home .home-games {
        width: 1250px;
        position: relative;
        padding: 70px 0;
        background: radial-gradient(45% 37% at 50% 105%, #3b4283 0%, rgba(3, 28, 49, 0) 100%);
    }

    
    .home .home-games {
        padding: 65px 0 40px 0;
        background: radial-gradient(45% 37% at 50% 105%, rgba(83, 69, 168, 0.65) 0%, rgba(3, 28, 49, 0) 100%);
        margin-top: 50px;
    }

    .home .home-featured::before,
    .home .home-games::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #00ffc2 50%, rgba(2, 25, 44, 0) 100%);
        z-index: -1;
    }

    .home .home-games::before {
        background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #3b7eb7 50%, rgba(2, 25, 44, 0) 100%);
    }

    .home .home-games::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #5345a8 50%, rgba(2, 25, 44, 0) 100%);
    }

    .home .featured-title,
    .home .games-title {
        width: 205px;
        height: 43px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Rubik';
        font-size: 24px;
        font-weight: 900;
        color: #ffffff;
    }

    .home .featured-title::before,
    .home .games-title::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(255deg, #00ffc2 -25%, #00aa6d 100%);
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    .home .games-title::before {
        background: linear-gradient(255deg, #3b7eb7 -25%, #145081 100%);
    }

    .home .featured-content,
    .home .games-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 1600px) {

        .home .home-banner,
        .home .home-featured,
        .home .home-games {
            width: 100%;
        }

    }

    @media only screen and (max-width: 950px) {

        .home {
            padding: 0px 10px 443px 10px;
        }

    }
</style>