<template>
    <nav id="navbar">
        <div class="navbar-left">
         
         
          <div class="link-home2">
  <div class="link-inner2" @click="toggleSidebar">
    <svg
      :class="{ rotated: isRotated }"
      version="1.1"
      viewBox="0 0 2048 1792"
      width="40"
      height="40"
      xmlns="http://www.w3.org/2000/svg"
      fill="#0dd4b1"
      data-v-72d9357e
    >
      <path
        transform="translate(1279,520)"
        d="m0 0h9l16 4 25 12 13 10 10 9 12 11 258 258 7 8 12 15 16 24 4 9 2 9v25l-3 12-9 13-13 16-11 12-12 12-3 4h-2v2l-8 7-253 253-11 9-14 12-10 7-12 5-5 1h-23l-17-4-10-5-10-8-7-7-11-15-8-15-3-9-1-13 3-13 8-16 7-12 12-16 44-44 1-2h2l1-3h2l2-4 53-53 8-7 5-6 44-44 5-4 1-2h-86l-723 1h-83l-45-1-33-4-15-6-10-9-10-12-10-15-5-14-2-11v-11l3-15 5-12 8-12 12-13 15-10 15-7 4-1 17-1h933l3 1-12-17-15-16-17-17-3-2v-2l-4-2-22-22-6-7-3-2v-2l-3-1-4-5-8-7-54-54-7-8-11-14-10-15-9-17-4-12v-8l4-16 10-19 8-12 10-10 19-10 17-7z"
        class="sidebaropen"
        data-v-72d9357e
      ></path>
      <path
        transform="translate(450,1138)"
        d="m0 0h494l15 1 15 4 24 8 12 7 8 9 8 17 7 17 7 11-1 6-8 16-9 21-4 8-9 10-5 4-31 10-5 4-6 1h-470l-31-1-11-2-26-8-12-7-9-9-9-13-8-16-3-12v-16l4-16 7-14 9-12 9-9 9-6 17-8z"
        class="sidebaropen"
        data-v-72d9357e
      ></path>
      <path
        transform="translate(472,511)"
        d="m0 0h455l42 1 17 1 8 6 21 18 9 13 7 16 5 16v12l-5 16-8 16-6 8-5 6-11 9-11 7-10 4-21 3-20 1h-471l-16-2-18-5-14-7-11-9-6-5-9-11-6-12-4-15v-20l3-14 6-14 12-13 14-13h2l1-3h2l2-4 6-4 3-1z"
        class="sidebaropen"
        data-v-72d9357e
      ></path>
    </svg>
  </div>
</div>

           
<router-link to="/" class="link-logo-mobile"> 
                <img src="@/assets/img/ranks/admin.svg" />
            </router-link>

            <router-link to="/" class="link-logo">
                <img src="@/assets/img/logo.png" />
            </router-link>
            <router-link to="/rewards" class="link-rewards">
                    <img src="@/assets/img/chest_coins.png" />
                    <span>{{ $t('navbar.1') }}</span>
                    <span>{{ $t('navbar.2') }}</span>
                </router-link>
        </div>

        <div class="navbar-mid">
            
            <NavbarCashier />
        </div>

        <div class="navbar-right">
            <AuthButton v-if="authUser.user === null" />
            <div v-else class="right-auth">
              
                <NavbarUserDropdown />
                <div class="language-icon" @click="toggleLanguageDropdown">
                <img :src="currentLanguageIcon" alt="Language" />
            </div>
            <div v-if="showLanguageDropdown" class="language-dropdown">
                <div 
                    v-for="(icon, lang) in languageOptions" 
                    :key="lang" 
                    class="language-option" 
                    @click="changeLanguage(lang)"
                >
                    <img :src="icon" :alt="lang" />
                    <span>{{ lang.toUpperCase() }}</span>
                </div>
            </div>

        
                <div @click="toggleNotificationsModal" class="notification-icon">
        <img src="@/assets/img/notification.png" alt="Notifications" />
      </div>

      <div class="chat-icon" @click="handleChatButtonClick">
    <img src="@/assets/img/chat.png" alt="Chat" />
</div>


            </div>
          
            
        </div>

  
      <!-- Bildirim Modalı -->
      <div v-if="showNotificationsModal" class="modal-overlay" @click.self="toggleNotificationsModal">
        <div class="modal-content">
          <button @click="toggleNotificationsModal" class="close-button">×</button>
          <h2>{{ $t('navbar.3') }}</h2>

          <!-- Yükleniyor Göstergesi -->
          <p v-if="loading" class="loading-message">{{ $t('navbar.4') }}</p>

          <!-- Hata Mesajı -->
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

          <!-- Bildirim Listesi -->
          <ul v-if="!loading && notifications.length" class="notification-list">
            <li v-for="notification in notifications" :key="notification._id" class="notification-item">
              <h3>{{ notification.title }}</h3>
              <p>{{ notification.message }}</p>
              <span class="notification-date">{{ formatDate(notification.createdAt) }}</span>
            </li>
          </ul>

          <!-- Bildirim Yok Mesajı -->
          <p v-else-if="!loading && !notifications.length" class="no-notifications">
            {{ $t('navbar.5') }}
          </p>
        </div>
      </div>
     
       
    </nav>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import IconChatGradient from '@/components/icons/IconChatGradient';
    import IconNavbarGradient from '@/components/icons/IconNavbarGradient';
    import IconHome from '@/components/icons/IconHome';
    import IconUser from '@/components/icons/IconUser';
    import IconVault from '@/components/icons/IconVault';
    import IconRakeback from '@/components/icons/IconRakeback';
    import IconAffiliates from '@/components/icons/IconAffiliates';
    import IconLeaderboard from '@/components/icons/IconLeaderboard';
    import IconSignOut from '@/components/icons/IconSignOut';
    import IconCrash from '@/components/icons/IconCrash';
    import IconBlackjack from '@/components/icons/IconBlackjack';
    import IconRoll from '@/components/icons/IconRoll';
    import IconDuels from '@/components/icons/IconDuels';
    import AuthButton from '@/components/AuthButton';
    import NavbarHomeDropdown from '@/components/navbar/NavbarHomeDropdown';
    import NavbarCashier from '@/components/navbar/NavbarCashier';
    import NavbarUserDropdown from '@/components/navbar/NavbarUserDropdown';
    import ModalNotifications from '@/components/modals/ModalNotifications'; // Bildirim modalı bileşeni
    import axios from 'axios';
    export default {
        name: 'Navbar',
        components: {
            IconChatGradient,
            IconNavbarGradient,
            IconHome,
            IconUser,
            IconVault,
            IconRakeback,
            IconAffiliates,
            IconLeaderboard,
            IconSignOut,
            IconCrash,
            IconBlackjack,
            IconRoll,
            IconDuels,
            AuthButton,
            NavbarHomeDropdown,
            NavbarCashier,
            NavbarUserDropdown,
           
        },
        data() {
    return {
      showNotificationsModal: false,
      notifications: [],
      loading: false,
      errorMessage: '',
      isRotated: false, // SVG'nin döndüğünü izleyen durum
      showLanguageDropdown: false,
            currentLanguage: this.$i18n.locale,
           languageOptions: {
    en: require('@/assets/img/flags/united-states-of-america.svg'),
    tr: require('@/assets/img/flags/tr.png'),
    de: require('@/assets/img/flags/germany.svg'),
    pt: require('@/assets/img/flags/brasil.svg'),
    fr: require('@/assets/img/flags/france.svg'),
    it: require('@/assets/img/flags/italy.png'),
    zh: require('@/assets/img/flags/china.svg'),
    hi: require('@/assets/img/flags/hint.png'),
    ru: require('@/assets/img/flags/russia.png'),
    id: require('@/assets/img/flags/id.webp'),
    es: require('@/assets/img/flags/spain.svg'),
}

    };
  },
        methods: {
          toggleRotation() {
      this.isRotated = !this.isRotated; // Döndürme durumunu değiştir
    },
    toggleSidebar() {
      // Mevcut toggleSidebar işlevi
      console.log("Sidebar toggled");
    },
          toggleLanguageDropdown() {
        this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    changeLanguage(lang) {
        this.currentLanguage = lang;
        this.$i18n.locale = lang;
        localStorage.setItem('language', lang); // Yeni dili kaydedin
        this.showLanguageDropdown = false;
    },
    handleChatButtonClick() {
        // Chat butonuna tıklandığında aynı işlevi uygular
        this.generalSetSidebarMobile('Chat'); // Sidebar'ı "Chat" modu ile açar
        
    },
            toggleNotificationsModal() {
      this.showNotificationsModal = !this.showNotificationsModal;
      if (this.showNotificationsModal) {
        this.fetchNotifications(); // Modal açıldığında bildirimleri getir
      }
    },
    async fetchNotifications() {
      this.loading = true;
      this.errorMessage = '';
      try {
        const response = await axios.get(`https://play.rivobit.com/notices/${this.authUser.user._id}`);
        this.notifications = response.data;
      } catch (error) {
        console.error('Error fetching notifications:', error);
        this.errorMessage = 'Bildirimler yüklenirken bir hata oluştu.';
      } finally {
        this.loading = false;
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
            ...mapActions([
        'notificationShow', 
        'modalsSetShow', 
        'generalSetSidebarMobile', 
        'authLogoutUser',
        'toggleSidebar' // Sidebar açma/kapama fonksiyonunu Vuex'ten alıyoruz
    ]),
            navbarVaultButton() {
                this.modalsSetShow('Vault');
                this.generalSetSidebarMobile(null);
            }
        },
        created() {
    const savedLanguage = localStorage.getItem('language'); // Kaydedilen dili alın
    if (savedLanguage) {
        this.currentLanguage = savedLanguage; // Seçilen dili ayarlayın
        this.$i18n.locale = savedLanguage; // i18n dili güncelleyin
    }
},
        computed: {
          currentLanguageIcon() {
            return this.languageOptions[this.currentLanguage];
        },
            ...mapGetters([
                'generalSidebarMobile', 
                'authUser'
            ])
        }
    }
</script>

<style scoped>
    nav#navbar {
        width: 100%;
        height: 60px;
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 0;
        left: 0;
        
        background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
    background-color: #090f1e;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
        z-index: 50;
    }

    nav#navbar .navbar-left,
    nav#navbar .navbar-right  {
        width: calc(50% - 176px);
        display: flex;
        align-items: center;
        z-index: 1;
    }

    nav#navbar .navbar-right  {
        justify-content: flex-end;
    }

  

    nav#navbar .navbar-left a.link-logo {
        display: flex;
        align-items: center;
        margin-right: 30px;
    }

    nav#navbar .navbar-left a.link-logo img {
        height: 41px;
        width: 100%;
    }

    nav#navbar .navbar-left a.link-home {
        width: 50px;
        height: 50px;
        position: relative;
        margin-right: 10px;
        padding: 1px;
        z-index: 1;
    }

    nav#navbar .navbar-left a.link-home::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 133, 255, 0) 0%, #328adb 100%);
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    nav#navbar .navbar-left a.link-home::after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background: #061628;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    nav#navbar .navbar-left a.link-home .link-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(255deg, rgba(59, 126, 183, 0.5) -70%, rgba(20, 80, 129, 0.5) 90%);
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        transition: background 0.3s ease;
    } 

    nav#navbar .navbar-left a.link-home:hover .link-inner {
        background: linear-gradient(255deg, #3b7eb7 -70%, #145081 90%);
    }

    nav#navbar .navbar-left a.link-home .link-inner svg {
        fill: #6e95b6;
        transition: fill 0.3s ease;
    }

    nav#navbar .navbar-left a.link-home:hover .link-inner svg {
        fill: #ffffff;
    }

    nav#navbar .navbar-mid button.auth-button {
        display: none;
    }

    nav#navbar .right-auth {
        display: flex;
        align-items: center;
    }

    nav#navbar a.link-rewards {
        width: 155px;
        height: 45px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 25px;
        padding: 0 15px 0 50px;
        border-radius: 5px;
        background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
    }

    nav#navbar a.link-rewards img {
        width: 71px;
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translate(0, -50%);
        filter: drop-shadow(0px 0px 4px rgba(255, 245, 59, 0.35));
    }

    nav#navbar a.link-rewards span {
        font-family: 'Rubik';
        font-size: 14px;
        font-weight: 900;
        background: linear-gradient(180deg, #ffffff 1.75%, rgba(108, 255, 211, 0.85) 35%, rgba(0, 255, 71, 0.55) 70.93%, #7bff90 95%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        filter: drop-shadow(0px 0px 10px rgba(255, 10, 10, 0.25));
    }

    nav#navbar .navbar-right button.button-toggle {
        width: 36px;
        height: 28px;
        display: none;
        filter: drop-shadow(0px 1.8px 1.8px rgba(0, 0, 0, 0.10));
    }

    nav#navbar .navbar-right button.button-toggle .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #214059;
        clip-path: polygon(4px 0, calc(100% - 4px) 0, 100% 25%, 100% 75%, calc(100% - 4px) 100%, 4px 100%, 0 75%, 0 25%);
    }

    nav#navbar .navbar-right button.button-toggle .button-inner svg {
        fill: #5b7b95;
        transition: fill 0.3s ease;
    }

    nav#navbar .navbar-right button.button-toggle:hover .button-inner svg {
        fill: #ffffff;
    }

    nav#navbar .navbar-mobile {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%); /* Başlangıçta gizli */
    padding: 95px 21px 0 21px;
    overflow-y: scroll;
    background-color: #021423;
    z-index: 999; /* En üstte görüntülenmesi için */
    transition: transform 0.3s ease;
}

    nav#navbar .navbar-mobile a,
    nav#navbar .navbar-mobile button {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #bbbfd0;
        border-bottom: 1px solid #102b3f;
    }

    nav#navbar .navbar-mobile .mobile-user a,
    nav#navbar .navbar-mobile .mobile-games a,
    nav#navbar .navbar-mobile .mobile-user button {
        margin-top: 25px;
    }

    nav#navbar .navbar-mobile a.router-link-exact-active,
    nav#navbar .navbar-mobile a.link-blackjack.router-link-active {
        background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    nav#navbar .navbar-mobile a::before,
    nav#navbar .navbar-mobile button::before {
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #102b3f;
    }

    nav#navbar .navbar-mobile a svg,
    nav#navbar .navbar-mobile button svg {
        width: 13px;
        margin-right: 8px;
        fill: #bbbfd0;
    }

    nav#navbar .navbar-mobile a.router-link-exact-active svg,
    nav#navbar .navbar-mobile a.link-blackjack.router-link-active svg {
        fill: #00ffc2;
    }

    nav#navbar .mobile-games {
        margin-top: 40px;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
    }

    @media only screen and (max-width: 1750px) {

        nav#navbar {
            
        }

    }

    @media only screen and (max-width: 1650px) {

        nav#navbar .left-rakeback,
        nav#navbar .right-links {
            display: none;
        }
       

    }

    @media only screen and (max-width: 1500px) {

        nav#navbar {
            width: 100%;
        }

    }

    @media only screen and (max-width: 1175px) {

        nav#navbar {
            
           
            background: rgba(9, 40, 65, 0.48);
            border-bottom: 1px solid rgba(27, 64, 92, 0.35);
        }

        nav#navbar .navbar-left, 
        nav#navbar .navbar-right {
            width: auto;
        }

        nav#navbar .navbar-left button.button-chat,
        nav#navbar .navbar-right button.button-toggle {
            display: none;
        }
        
       
        nav#navbar .navbar-right button.auth-button,
        nav#navbar .right-auth {
            display: flex;
        }

        nav#navbar a.link-rewards,
        nav#navbar .navbar-left a.link-logo,
        nav#navbar .navbar-left a.link-home,
        nav#navbar .navbar-left .navbar-home-dropdown
        nav#navbar .navbar-right .notification-icon
         {
            display: none;
        }

        nav#navbar .navbar-mid button.auth-button {
            display: block;
        }

        nav#navbar .navbar-mobile.mobile-open {
            transform: translate(0, 0);
        }

    }
    .sidebar {
  position: fixed;
  left: 0;
  top: 87px;
  height: 100vh;
  width: 16.375rem; /* Sidebar'ın tam genişliği */
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
  background: linear-gradient(180deg, #062641 0%, #031422 140%);
  backdrop-filter: blur(0.625rem);
  border-radius: 0.9375rem;
  margin: 0.9375rem;
  height: calc(90% - 1.875rem);
}

.category-path {
    fill: #6e95b6; /* Varsayılan renk */
    transition: fill 0.3s ease;
  }

  .category-button:hover .category-path {
    fill: #ffffff; /* Hover olduğunda renk */
  }

  .link-home2 {
    width: 50px;
    height: 50px;
    position: relative;
    
    padding: 1px;
    z-index: 1;
    top: 0;
    left: 0;
    content: '';
  }
  .link-inner2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease;
  }
  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 30px 30px 30px;
    border-radius: 15px;
    background: radial-gradient(100% 100% at 50% 0%, rgba(0, 255, 194, 0.15) 0%, rgba(7, 38, 61, 0) 80%), linear-gradient(256deg, #07263d 0%, #07243a 100%);
    width: 400px;
}

.notification-icon {
  width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    background: #021726;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
}

.notification-icon img {
  width: 24px;
  height: 24px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #6c757d;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #000000;
}

h2 {
  margin-top: 0;
  font-size: 24px;
  background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.notification-item {
  background-color: #1a4f63;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  width: 300px;
}

.notification-item:hover {
  background-color: #1a4f63;
}

.notification-item h3 {
  margin: 0;
  font-size: 18px;
  color: #bbbfd0;
}

.notification-item p {
  margin: 5px 0;
  font-size: 14px;
  color: #bbbfd0;
}

.notification-date {
  font-size: 12px;
  color: #888;
}

.loading-message,
.error-message,
.no-notifications {
  font-size: 16px;
  color: #555;
  text-align: center;
}

@media only screen and (max-width: 1175px) {
    .notification-icon {
        display: none; /* Username ve level tamamen gizlenir */
    }

}



.language-icon {
  width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    background: #021726;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
  
}

.language-icon img {
    width: 24px;
    height: 24px;
    border-radius: 50%; /* Bayrakları yuvarlak yapmak için */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.language-icon img:hover {
    transform: scale(1.1); /* Hover'da büyütme efekti */
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.6); /* Hafif bir gölge efekti */
}

.language-dropdown {
    position: absolute;
    top: 60px;
    right: 0;
    width: 150px;
    background: #0c2a44;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease;
}

.language-option {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.language-option:hover {
    background-color: #1a4f63;
    transform: scale(1.05); /* Hover'da hafif büyütme efekti */
}

.language-option img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.language-option img:hover {
    transform: rotate(10deg); /* Hover'da küçük bir dönüş efekti */
}

.language-option span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}

.language-dropdown::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #0c2a44; /* Dropdown okunun rengi */
}

.chat-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #021726;
    border-radius: 10px;
    margin-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
}

.chat-icon img {
    width: 24px;
    height: 24px;
}

.chat-icon.has-notification::after {
    display: block; /* Bildirim varsa göster */
}

@media only screen and (max-width: 768px) {
    .chat-icon {
        display: none; /* Mobil cihazlarda Chat butonunu gizler */
    }
}
svg {
  transition: transform 0.3s ease;
}

svg.rotated {
  transform: rotate(180deg);
}

.link-logo-mobile {
  display: none; /* Varsayılan olarak gizle */
}

@media only screen and (max-width: 768px) {
  .link-logo-mobile {
    display: block; /* Mobil cihazlarda görünür yap */
    width: 40px; /* Genişlik 50px */
    height: 40px; /* Yükseklik 50px */
    margin: 0 auto; /* Ortalamak için */
    text-align: center;
  }

  .link-logo-mobile img {
    width: 40px; /* Resim genişliği */
    height: 40px; /* Resim yüksekliği */
    object-fit: cover; /* Resmin oranlarını koru ve alanı doldur */
  }
}


</style>

